<template>
  <div id="main">
    <DFSHeader active="物种树" />
    <Content />
    <DFSFooter />
  </div>
</template>

<script>
import DFSHeader from '@/components/DFSHeader';
import DFSFooter from '@/components/DFSFooter';
import Content from './components/Content.vue';

export default {
  name: 'Tree',
  components: {
    DFSHeader,
    DFSFooter,
    Content
  }
};
</script>
