<template>
  <div id="dfs-header">
    <div id="dfs-header-title">
      <h1><a href="/">病媒生物数字化标本库</a></h1>
    </div>
    <DFSNavbar :active="active" />
  </div>
</template>

<script>
import DFSNavbar from './DFSNavbar';

export default {
  name: 'DFSHeader',
  props: {
    active: String
  },
  components: {
    DFSNavbar
  }
}
</script>

<style lang="stylus" scoped>
@import "~/src/assets/styles/variables.styl"
#dfs-header
  background: $colorPrimary
  height: 11.5rem
  position: relative

  #dfs-header-title
    width: $mainContentWidth
    margin: 0 auto
    padding-top: 3rem

    h1
      a
        color: #FFF
        font-size: 2rem
        font-weight: 350
</style>
