<template>
  <div id="dfs-footer">
    <p class="dfs-footer-copyright">
      版权所有&copy;国家医学媒介生物监测重点实验室（广东）
      &nbsp;&nbsp;
      <a href="https://beian.miit.gov.cn" target="_blank">
        备案号：浙ICP备2020033205号
      </a>
    </p>
  </div>
</template>

<script>
export default {
  name: "DFSFooter"
}
</script>

<style lang="stylus" scoped>
@import "~/src/assets/styles/variables.styl"
#dfs-footer
  width: $mainContentWidth
  height: 3.5rem
  line-height: 3.5rem
  border-top: 1px solid #CCC
  margin: 0 auto
</style>