import service from '@/utils/service';
import { dictToURI } from '@/utils/url';


export function getAllKingdoms() {
  return service({
    method: 'get',
    url: '/v1/taxons/kingdoms/'
  });
}


export function getBaseTreeData() {
  return service({
    method: 'get',
    url: '/v1/taxons/base-tree/'
  });
}


export function getTaxonChildren(slug) {
  return service({
    method: 'get',
    url: '/v1/taxons/' + slug + '/children/'
  });
}


export function getSpeciesByTaxon(slug) {
  return service({
    method: 'get',
    url: '/v1/taxons/' + slug + '/species/'
  });
}

export function createTaxon(taxon) {
  return service({
    method: 'post',
    url: '/v1/taxons/',
    data: taxon
  });
}

export function destroyTaxon(slug) {
  return service({
    method:'delete',
    url: '/v1/taxons/' + slug + '/'
  });
}

export function updateTaxon(taxon) {
  var data = {...taxon};
  const slug = data.slug;
  delete data.slug;
  return service({
    method: 'patch',
    url: '/v1/taxons/' + slug + '/',
    data: data
  });
}


export function listTaxons(queryParams) {
  return service({
    method: 'get',
    url: '/v1/taxons/?' + dictToURI(queryParams)
  });
}

export function list(queryParams) {
  return service({
    method: 'get',
    url: '/v1/taxons/?' + dictToURI(queryParams)
  });
}