<template>
  <div id="main-content">
    <a-card title="浏览分类树">
      <a-tree
        v-if="treeData.length > 0"
        class="taxon-tree"
        :show-line="true"
        :default-expanded-keys="expandKeys"
        :tree-data="treeData"
        @expand="onExpand"
        @select="onSelect"
      >
      </a-tree>
    </a-card>
  </div>
</template>

<script>
import {formatTaxon} from '@/utils/tree';
import {
  getBaseTreeData,
  getTaxonChildren,
  getSpeciesByTaxon
} from '@/services/taxon';

export default {
  name: 'Content',
  data() {
    return {
      treeData: [],
      expandKeys: []
    }
  },
  methods: {
    initTreeData() {
      getBaseTreeData().then(res => {
        this.treeData = res.data.data.tree_data;
        this.expandKeys = res.data.data.expand_keys;
      });
    },
    onExpand(expandKeys, {node}) {
      if (node.dataRef.children && (node.dataRef.children.length > 0)) {
        return;
      }
      getTaxonChildren(node.eventKey).then(res => {
        var childrenData = [];
        res.data.data.forEach(element => {
          childrenData.push(formatTaxon(element));
        });
        node.dataRef.children = childrenData;
        this.treeData = [...this.treeData];
      });
    },
    onSelect(selectedKeys, {node}) {
      if (! node.dataRef.isLeaf) {
        return;
      }
      getSpeciesByTaxon(node.eventKey).then(res => {
        const slug = res.data.data.slug;
        this.$router.push('/species/' + slug);
      });
    }
  },
  mounted() {
    this.initTreeData();
  }

}
</script>

<style lang="stylus" scoped>
#main-content
  padding-top: 2rem
  .taxon-tree
    min-height: 45rem
</style>
