<template>
  <ul class="dfs-navbar">
    <li
        :class="{'dfs-nav-item': true, active: active === item.title}"
        :key="item.url" v-for="item in items"
    >
      <a :href="item.url">{{item.title}}</a>
    </li>
    <li class="dfs-nav-item-user" v-if="userInfo">
      欢迎，{{userInfo.username}}
    </li>
  </ul>
</template>

<script>
import storage from '@/utils/storage';

export default {
  name: "DFSNavbar",
  props: {
    active: String
  },
  methods: {
  },
  data() {
    const userInfo = storage.getUserInfo();
    let items = [
      {title: '物种树', url: '/tree'},
      {title: '分布地', url: '/distribution'},
      {title: '采集地', url: '/collection'},
      {title: '检索', url: '/search'},
      {title: '比对', url: '/align'},
    ];
    if (userInfo && userInfo['is_superuser']) {
      items.push({title: '管理', url: '/admin/species'});
    }
    return {
      userInfo: userInfo,
      items: items
    }
  }
}
</script>

<style lang="stylus" scoped>
@import "~/src/assets/styles/variables.styl"
.dfs-navbar
  padding: 0
  position: absolute
  bottom: 0
  left: 0
  right: 0
  margin: auto
  width: $mainContentWidth

  .dfs-nav-item
    display: inline-block
    border-radius: .1rem

    a
      display: inline-block
      min-width: 5rem
      height: 2.5rem
      line-height: 2.5rem
      font-size: 1.2rem
      text-align: center
      color: #FFF

  .dfs-nav-item.active
    background: #FFF

    a
      color: #444

  .dfs-nav-item:hover
    background: #ABBFDA
    cursor: pointer

    a
      color: #444

  .dfs-nav-item-user
    float: right
    list-style: none
    height: 2.5rem
    line-height: 2.5rem
    padding-left: 1.5rem
    padding-right: 1.5rem
    border-radius: .1rem
    font-size: 1.2rem
    color: #FFF
</style>
