function formatTitle(taxon) {
  if (taxon.children_count > 0) {
    return taxon.sciname + ' ' + taxon.name + '（' + taxon.children_count + '）';
  }
  return taxon.sciname + ' ' + taxon.name;
}


function isLeaf(taxon) {
  return taxon.children_count === 0;
}


export function formatTaxon(taxon) {
  return {
    title: formatTitle(taxon),
    key: taxon.slug,
    isLeaf: isLeaf(taxon)
  };
}